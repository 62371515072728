import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobileView();
    window.addEventListener('resize', checkMobileView);

    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(false);
    }
  }, [isMobile]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinks = [
    { to: '/', label: 'Home' },
    { to: '/features', label: 'Features' },
    { to: '/blogs', label: 'Blogs' },
    { to: '/contact', label: 'Contact Us' }
  ];

  return (
    <div className={`fixed top-4 ${isMobile ? 'left-4 right-4' : 'left-[110px] right-[110px]'} z-50`}>
      <nav className="bg-[#D5F8E4] rounded-full shadow-sm transition-all duration-300 ease-in-out">
        <div className="max-w-7xl mx-auto px-2 py-2 flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img 
              src="/SereniMindLogoMain.png" 
              alt="SereniMind Logo" 
              className={`transition-all duration-300 ease-in-out ${isMobile ? 'h-[42px] w-[57px]' : 'h-[62px] w-[77px]'}`}
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8 font-semibold">
            {navLinks.map((link) => (
              <Link 
                key={link.to} 
                to={link.to} 
                className="text-gray-700 transition-all duration-300 ease-in-out hover:text-[#ffffff] hover:bg-[#ffa07a] hover:px-3 hover:py-2 hover:rounded-full font-['Poppins']"
              >
                {link.label}
              </Link>
            ))}
          </div>

          {/* Auth Buttons */}
          <div className={`${isMobile ? 'hidden' : 'md:flex'} items-center space-x-4 border-[#FFA07A] border-2 p-1 rounded-full bg-white transition-all duration-300 ease-in-out`}>
            <Link
              to="/login"
              className="text-[#FFA07A] font-bold px-4 py-2 transition-colors duration-300 ease-in-out hover:text-[#ff7f50]"
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="border-[#31452C] border-2 text-[#31452C] px-4 py-2 rounded-full font-semibold bg-white transition-all duration-300 ease-in-out hover:bg-[#31452C] hover:text-white"
            >
              Register
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className={`${isMobile ? 'block' : 'hidden'} text-gray-700 transition-transform duration-300 ease-in-out ${isOpen ? 'rotate-90' : ''}`}
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16m-16 6h16" />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobile && isOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-[9999]"
            onClick={toggleMenu}
          >
            <div 
              className="absolute top-0 right-0 w-64 h-full bg-[#D5F8E4] transform transition-transform duration-300 ease-in-out translate-x-0"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-6 pt-20 space-y-4">
                {navLinks.map((link) => (
                  <Link
                    key={link.to}
                    to={link.to}
                    className="block text-gray-700 text-lg py-2 hover:text-[#59844F] transition-colors duration-300 ease-in-out"
                    onClick={toggleMenu}
                  >
                    {link.label}
                  </Link>
                ))}
                <div className="border-t pt-4 space-y-2">
                  <Link
                    to="/login"
                    className="block text-gray-700 text-lg py-2 hover:text-[#59844F] transition-colors duration-300 ease-in-out"
                    onClick={toggleMenu}
                  >
                    Login
                  </Link>
                  <Link
                    to="/signup"
                    className="block bg-white border-2 border-[#31452C] text-[#31452C] text-lg hover:bg-[#31452C] hover:text-white px-4 py-2 rounded-full text-center transition-all duration-300 ease-in-out"
                    onClick={toggleMenu}
                  >
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default NavBar;